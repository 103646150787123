import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import SEO from "components/SEO";

export const query = graphql`
  query PurchaseQuery($langKey: String!) {
    site {
      siteMetadata {
        keywords
        description
      }
    }
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }
    ) {
      nodes {
        frontmatter {
          brand
          anchor
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const Purchase = ({ data, pathContext: { langKey } }) => {
  const {
    site: { siteMetadata: { keywords, description } },
    // allMarkdownRemark: { nodes },
  } = data;

  return (
    <>
      <SEO lang={langKey} title="Purchase" keywords={keywords} description={description} />
      <h1>{langKey}</h1>
    </>
  );
};

Purchase.propTypes = {
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object,
};

Purchase.defaultProps = {
  pathContext: {
    langKey: "en",
    defaultLang: "en",
    langTextMap: {},
  },
};

export default Purchase;
